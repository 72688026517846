import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select, SelectProps } from '@shopify/polaris';
import { getTranslation } from '../util/getTranslation';

type TProps = Omit<SelectProps, 'options'> & {
  name: string;
  label: string;
  options: SelectProps['options'];
  required?: boolean;
};

export function FormSelectField({
  name,
  label,
  options,
  required = true,
  ...fieldProps
}: TProps) {
  const { setValue, getFieldState, control, formState } = useFormContext();
  const { field } = useController({
    name,
    rules: {
      required: required && getTranslation('Vyberte hodnotu', 'Select a value'),
    },
    control,
  });

  return (
    <Select
      {...fieldProps}
      label={label}
      options={options}
      value={field.value}
      onChange={(value) => {
        if (field.value !== value) {
          setValue(name, value, { shouldDirty: true, shouldValidate: true });
        }
      }}
      error={getFieldState(name).error?.message}
      disabled={formState.isSubmitting}
    />
  );
}
